@use '~theme/colors' as *;
@use '~theme/typography' as *;

.time-input-container {
    display: flex;
    flex-direction: column;
    margin: 4px 20px 4px 0px;

    .flex {
        display: flex;
        position: relative;
    }

    .label {
        margin-bottom: 4px;
        font-size: 14px;
        line-height: 20px;
    }

    .input-time {
        width: 162px;
        padding: 4px 8px;
        border: 1px solid lightgray;
        margin-right: -1px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;

        &.hour24-format {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            width: 226px;
        }

        &.error {
            border-color: $pomegranate !important;
            margin-right: 0;
        }

        &:focus {
            outline: 2px solid transparent;
            outline-offset: 2px;
            box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow);
            --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
            --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
            --tw-ring-color: $blue-ribbon;
        }
    }

    .time-select {
        padding: 4px 8px;
        border: 1px solid lightgray;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        cursor: pointer;

        &:focus {
            box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
            outline: 2px solid transparent;
            outline-offset: 2px;
            --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
            --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
            --tw-ring-color: $blue-ribbon;
        }
    }

    .error-icon {
        position: absolute;
        right: 75px;
        top: 3px;
        z-index: 9;

        &.hour24-format-icon {
            right: 10px;
        }

        svg {
            border-radius: 10px;
        }
    }

    .error-message {
        color: red;
    }
}