
@use "~theme/colors"as *;

.highcharts-legend {
	.highcharts-legend-item {
        .legend-item-wrapper {
            display: flex;
			font-weight: 700;
            white-space: pre;
            .signal-source-name {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .cross-asset__legend {
                overflow: hidden;
                text-overflow: ellipsis;
                width: fit-content;
            }
        }

        .item-kpi-name {
            font-weight: 400;
            width: 100%;
            max-width: 17rem;
            white-space: nowrap; 
            overflow: hidden;
            text-overflow: ellipsis;
		}
        .cross-asset__legend {
            overflow: initial;
            text-overflow: unset;
            max-width: unset;
        }

		.remove-button {
			padding-left: 6px;
		}
	}
}
