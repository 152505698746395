/**
 *
 * SearchBoxComponent styles
 *
 */

@use '~theme/colors' as *;
@use '~theme/typography' as *;
@use '~theme/mixins' as *;
@use "~theme/breakpoints" as *;

.search-field {
	@include create-border($silver, 0px);
	width: 250px;
	min-width: 230px;
	display: flex;
	float: right;
	align-items: center;
    background: $white;
	height: 33px;

	@include xl-max-w {
		margin: 0 auto;
	}

	@include md-max-w {
		width: 240px;
	}

	.glyphicon-search {
		height: 25px;
		line-height: 25px;
		color: $gallery;
		width: 38px;
		border-left: 1px solid $alto;
		text-align: center;
		margin-top: 0px;
		padding-top: 2px;
	}

	.form-control::-ms-clear {
		display: none;
	}

	.search-input-container {
		width: 100%;
		padding-left: 0px;
		float: left;
		height: 30px;
		margin: 0;
	}

	.search-field-component {
		position: relative;
		background: transparent;
		border: none;
		margin: 0;
		display: flex;
		width: 100%;

		.close-button-container {
			position: absolute;
			top: 4px;
			right: 0px;
			cursor: pointer;
			border-right: 1px solid $silver-chalice;
		}
	}

	.search-button-container {
		padding: 0 6px;
	}

	input {
		border: none;
		box-shadow: none;
		text-align-last: left;

        &.form-control {
            @include font-regular-text-md;
            height: 30px;
            font-style: normal;
            line-height: 16px;

            &::placeholder {
                @include font-regular-text-md;
                font-weight: normal;
                line-height: 16px;
                font-style: normal;
                color: $silver-chalice;
            }

            &::-moz-placeholder {
                @include font-regular-text-md;
                font-weight: normal;
                line-height: 16px;
                font-style: normal;
                color: $silver-chalice;
            }

            &::-ms-input-placeholder {
                @include font-regular-text-md;
                font-weight: normal;
                line-height: 16px;
                font-style: normal;
                color: $silver-chalice;
            }

            &:focus,
            &:active {
                box-shadow: none;
                border: none;
            }
        }
	}

	&.disabled {
		background: $gallery;

		input {
			background: $gallery;
		}
	}

	&.highlight {
		box-shadow: 0px 0px 2px $dodger-blue !important;
		border-width: 1px !important;
		border-color: $dodger-blue !important;
	}
}
