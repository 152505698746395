@use '~theme/colors' as *;
@use '~theme/mixins' as *;
@use '~theme/typography' as *;

.tab-header {
    display: flex;
}

.tab-item {
    padding: 8px 0;
    margin: 0 20px 0 0;
    cursor: pointer;
    background: none;
    border: none;
    outline: none;
    @include font-regular-text-md;

    &:last-child {
        margin-right: 0;
    }
}

.tab-item.active {
    border: none;
    box-shadow: 0 2px 0 0 $dodger-blue;
}

.tab-content {
    margin-top: -1px;
}

.tab-component {
    &.toggle {
        .tab-header {
            background: $wild-sand;
            border-radius: 8px;
            padding: 5px 6px;
            display: flex;
            align-items: center;

            .tab-item {
                @include font-medium-text-md;
                box-shadow: none;
                padding: 4px 4px;
                line-height: 0;
                height: 30px;
                margin-right: 10px;

                &:last-child {
                    margin-right: 0;
                }

                &.active {
                    background-color: rgba(51, 102, 255, 0.16);
                    border-radius: 8px;

                }
            }
        }
    }
}