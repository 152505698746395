@use '~theme/colors' as *;
@use '~theme/mixins' as *;
@use '~theme/typography' as *;
@use '~theme/zIndexes' as *;
@use "~bootstrap/scss/bootstrap" as *;

.general-dialog-container.modal {
	z-index: $general-dialog-zIndex;

	.general-dialog {
		max-width: unset;
		width: 550px;
		max-height: calc(var(--vh)*100 - $modal-dialog-margin-y-sm-up * 2);

		.modal-header {
			@include font-bold-text-lg;
			line-height: 18.75px;
		}

		&.dialog-info {
			.modal-header {
				border-top: 4px solid $dodger-blue;
			}
		}

		&.dialog-success {
			.modal-header {
				border-top: 4px solid $jungle-green;
			}
		}

		&.dialog-warning {
			.modal-header {
				border-top: 4px solid $orange-peel;
			}
		}

		&.dialog-error {
			.modal-header {
				border-top: 4px solid $pomegranate;
			}
		}

		&.dialog-default {
			.modal-content {
				.modal-header {
					border-top: 4px solid $cod-gray;

					.general-dialog-header {
						padding: 24px 32px 16px 32px;
					}
				}

				.modal-body {
					padding: 0px 32px;
				}
			}
		}

		&>.modal-content {
			border-radius: 0px;

			.general-dialog-header {
				display: flex;
				align-items: center;
				padding: 24px 18px 16px 18px;

				.general-dialog-icon {
					margin-right: 12px;
				}

				.general-dialog-title {
					@include font-bold-text-lg;
					line-height: 18.75px;
				}
			}

			.modal-header {
				border-bottom: none;
				border-top-left-radius: 0;
				border-top-right-radius: 0;
				padding: 0;

				.btn-close {
					margin-right: 25px;
					padding: 0;
					color: $cod-gray;
					opacity: 1;

					&:focus {
						outline: none;
					}
				}
			}

			.modal-footer {
				@include font-regular-text-md;
				padding: 10px;
				border: none;

				.dialog-accept-button {
					padding: 0 15px;
					height: 30px;

					.button-text {
						line-height: 30px;
					}
				}

				.dialog-cancel-button {
					margin-right: 10px;
				}
			}

			.modal-body {
				padding: 0px 58px;
			}
		}
	}

	&.modal-pdf .general-dialog {
		height: 100%;
		max-width: 1024px;
		width: 100%;

		.modal-content {
			height: 100%;

			#pdf-viewer {
				height: 100%
			}
		}
	}
}

.general-dialog-backdrop.modal-backdrop {
	z-index: $general-dialog-zIndex;
}