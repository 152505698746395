
@use "~theme/colors"as *;

.highcharts-tooltip {
	span {
		font-size: 14px;

		.custom-trend-tooltip {
			padding: 15px;
			background-color: $white;
			border-width: 1px;
			border-style: solid;
			border-color: $mercury;
			box-shadow: 1px 1px $alto;

			b {
				color: $tundora;
			}

			.t-item {
				padding-top: 0px;

				span {
                    &.symbol {
                        display: inline-block;
                        width: 8px;
                        height: 8px;
                    }
				}
			}

			.t-header {
				font-weight: bold;
				font-size: 14px;
				margin-bottom: 10px;
				border-bottom: $alto solid 1px;
			}
			.t-personal-limit {
				display: flex;
  				align-items: center;
				color: $dove-gray;
				margin-bottom: 2px;
				margin-top: 4px;
			}
			.t-personal-limit:after {
				content: '';
				flex: 1;
				margin-left: 6px;
				height: 1px;
				background-color: $alto;
			}
		}
	}
}